import { httpClient } from '@cocoplatform/coco-rtc-client';
import React, { FormEvent, useState } from 'react';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import { useAuthState } from 'utils/auth-state';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import { reportServerError } from 'utils/report-error';
// import QrCodeIcon from '@mui/icons-material/QrCode';
import { useInvitationCode } from 'atoms/invitation-code-targets';
import { InvitationTarget } from 'components/join-code/JoinCodePage';
import ColBody from 'components/col-body/ColBody';
import Separator from 'components/prelogin-layout/Separator';
import { browserStorage } from 'utils/browser-storage';
import { LoadingButton } from '@mui/lab';
import { useCommunityDetails } from 'atoms/community-details';
import PasswordField from 'components/password-field/PasswordField';
import ROUTES from 'constants/routes';
import C from './LoginKeyJoinPage.css';
import { useFlags } from 'flagsmith/react';
import { useUserProfile } from 'atoms/user-profile';
import { Trans, msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useTutorial } from 'components/onboarding-tutorials/tutorial-atom';

export function BackToLoginKeyJoinPageLink(p: { code: string }) {
  const history = useHistory();
  const { code } = p;
  return (
    <div
      style={{ textAlign: 'right', paddingRight: '2rem' }}
      className={C.altVariantLink}
    >
      <a
        onClick={async () => {
          history.push(`/app/join/${code}/loginkey`);
        }}
      >
        {'<'} <Trans>Sign in with Username</Trans>
      </a>
    </div>
  );
}

export function MoreSigninOptions(p: { code: string }) {
  const history = useHistory();
  const { code } = p;
  return (
    <div
      style={{ textAlign: 'right', paddingRight: '2rem' }}
      className={C.altVariantLink}
    >
      <a
        onClick={async () => {
          history.push(`/app/join/${code}/email/signin`);
        }}
      >
        {'<'} <Trans>More sign in options</Trans>
      </a>
    </div>
  );
}

/* const QrReader = React.lazy(async () => {
  const { OneOffQrScanner } = await import('react-webcam-qr-scanner.ts');
  return { default: OneOffQrScanner };
}); */

export default function LoginKeyJoinPage() {
  const { _ } = useLingui();
  const { code, username: usernameParam } = useParams<{
    code: string;
    username?: string;
  }>();
  const history = useHistory();
  const [isSubmitting, setSubmitting] = useState(false);
  const { codeDetails } = useInvitationCode(code);
  const { isSignedIn } = useAuthState();
  const [username, setUsername] = useState(usernameParam);
  const [password, setPassword] = useState('');
  const { saveToken, setProfileCompletionState } = useAuthState();
  // const [qrReaderVisible, setQRReaderVisible] = useState(false);
  const [keepSignedIn, setKeepSignedIn] = useState(DEFAULT_PERSIST_SESSION);
  const { loadCommunityDetails } = useCommunityDetails({
    communityId: codeDetails?.target?.community?.id,
    autoLoad: false,
  });

  const { fetchUserProfile, userProfile } = useUserProfile();
  const { isProfileUpdated } = useAuthState();

  const { startGettingStartedTutorial } = useTutorial();

  // if (isSignedIn) return <Redirect to='/' />;

  // If this community has no login keys, or if we are already logged in
  // but the profile is not updated, redirect to the email sign in page
  if (!codeDetails?.hasLoginKeys || (userProfile && !isProfileUpdated)) {
    return <Redirect to={`/app/join/${code}/email/signin`} />;
  }

  return (
    <>
      {codeDetails?.target && (
        <div style={{ marginTop: '30px' }}>
          <InvitationTarget {...{ codeDetails }} />{' '}
        </div>
      )}
      <ColBody layout='narrow'>
        <form
          onSubmit={handleSubmit}
          style={{ marginTop: '1.5rem' }}
          autoComplete='off'
        >
          <FormControl fullWidth>
            <TextField
              value={username}
              onChange={(e) => {
                setUsername(e.currentTarget.value);
              }}
              placeholder={_(msg`Enter username`)}
              id='login_key_field'
              InputProps={{
                autoComplete: 'one-time-code',
              }}
            />
          </FormControl>
          <FormControl fullWidth style={{ marginTop: '10px' }}>
            <PasswordField
              value={password}
              onChange={(e) => {
                setPassword(e.currentTarget.value);
              }}
              placeholder={_(msg`Password`)}
              id='password_field'
              autoComplete='one-time-code'
            />
          </FormControl>
          <FormControl fullWidth style={{ marginTop: '1rem' }}>
            <LoadingButton
              loading={isSubmitting}
              variant='contained'
              color='primary'
              type='submit'
              id='sign-in-btn'
            >
              <Trans>Sign in</Trans>
            </LoadingButton>
          </FormControl>
          {/* qrReaderVisible && (
            <div className={C.videoContainer}>
              <QrReader
                onQrCode={(url) => {
                  setQRReaderVisible(false);
                  const match = url.match(
                    /\/join\/([a-z0-9]+)\/u\/([a-z0-9]+)$/i,
                  );
                  if (!match) {
                    reportServerError({
                      title: 'QR code is invalid',
                    });
                    return;
                  }
                  const username = match[2];
                  setUsername(username);
                }}
              />
            </div>
          ) */}
        </form>
        <div className={C.altVariantLink} style={{ margin: '1.5rem 0rem' }}>
          <Trans>Have an account?</Trans>{' '}
          <a
            onClick={async () => {
              history.push(`/app/join/${code}/email/signin`);
            }}
          >
            <Trans>Sign in with email</Trans>
          </a>
        </div>
      </ColBody>
    </>
  );

  async function handleSubmit(e: FormEvent) {
    e.stopPropagation();
    e.preventDefault();
    setSubmitting(true);
    browserStorage.selectStorage(keepSignedIn);
    try {
      const resp = await httpClient.post('/auth/login-allowance/verification', {
        username,
        password,
        targetType: 'community',
        code,
      });
      saveToken(resp.data.token, false);
      // TODO Update from server response
      setProfileCompletionState('PERMITTED_INCOMPLETE');
      if (codeDetails?.target?.community) {
        await loadCommunityDetails();
        history.push(
          `/app/communities/${codeDetails.target.community.id}/spaces`,
        );
      } else {
        history.push('/app');
      }
    } catch (error) {
      reportServerError({
        title: _(msg`Failed to validate username`),
        error,
      });
    } finally {
      setSubmitting(false);
    }
  }
}
