import React from 'react';
import C from './PreLoginLayout.css';
import PreLoginNavBar from 'components/prelogin-nav-bar/PreLoginNavBar';
import SharedLayout from 'components/shared-layout/SharedLayout';
import { Trans } from '@lingui/macro';
import Divider from '@mui/material/Divider';

export const Footer = () => (
  <div className={C.footer}>
    <div className={C.footerBody}>
      <div className={C.tocLinkContainer}>
        <div className={C.tocLinkContainer}>
          <a target='_blank' href='/privacy-terms'>
            <Trans>Privacy</Trans>
          </a>
          &
          <a target='_blank' href='/terms-of-use'>
            <Trans>Terms</Trans>
          </a>
        </div>
        <Divider orientation='vertical' variant='middle' flexItem />
        <div className={C.tocLinkContainer}>
          <a target='_blank' href='mailto:hello@coco.build'>
            <Trans>Contact Us</Trans>
          </a>
        </div>
      </div>
    </div>
  </div>
);

const PreLoginLayout: React.FC<{
  header?: React.ReactChild;
  footer?: React.ReactChild;
  children?: React.ReactChild;
  onSuccess?: () => void;
  wide?: boolean;
}> = (p) => {
  return (
    <SharedLayout
      {...p}
      header={p.header ?? <PreLoginNavBar />}
      footer={p.footer ?? <Footer />}
    />
  );
};

export default PreLoginLayout;
