import { useSpaceSummary } from 'atoms/spaces';
import { useUserProfile } from 'atoms/user-profile';
import { useFlagsmith } from 'flagsmith/react';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useAuthState } from 'utils/auth-state';
import { setFlagsmith } from '@cocoplatform/coco-rtc-client';

export const useFlagTraitEffect = () => {
  const flagsmith = useFlagsmith();
  const location = useLocation();
  const { userProfile } = useUserProfile();
  const corridorRegex = /\/app\/dashboard\/([A-za-z0-9-$]+)/;
  const corridorId = location.pathname.match(corridorRegex)?.[1] || '';

  const spaceRegex = /\/app\/spaces\/([A-za-z0-9-$]+)/;
  const spaceId = location.pathname.match(spaceRegex)?.[1] || '';

  const { space } = useSpaceSummary({ spaceId });

  useEffect(() => {
    flagsmith.identify(
      `browser_app_user_${(Math.random() + 1).toString(36).substring(7)}`,
    );
  }, []);

  useEffect(() => {
    if (flagsmith) {
      setFlagsmith(flagsmith);
    }
  }, [flagsmith]);

  useEffect(() => {
    if (
      userProfile?.id &&
      ((corridorRegex && corridorId) || (spaceRegex && spaceId && space))
    ) {
      const isPrimary = userProfile?.instanceRoles.includes(
        'PRIMARY_COMMUNITY_BUILDER',
      );
      const isSecondary = userProfile?.instanceRoles.includes(
        'SECONDARY_COMMUNITY_BUILDER',
      );

      let instanceRole = '';
      if (isSecondary) {
        instanceRole = 'SECONDARY_COMMUNITY_BUILDER';
      }

      if (isPrimary) {
        instanceRole = 'PRIMARY_COMMUNITY_BUILDER';
      }
      flagsmith.setTraits({
        userId: userProfile?.id,
        instanceRole: instanceRole,
        communityId: corridorId || space?.communityId || '',
        spaceId: spaceId,
        domain: window.location.hostname,
      });
    }
  }, [userProfile?.id, userProfile?.instanceRoles, corridorId, spaceId, space]);
};
