import React, { Suspense, useEffect } from 'react';
import {
  Route,
  Switch,
  Redirect,
  useLocation,
  useHistory,
} from 'react-router-dom';
import ROUTES from 'constants/routes';
import PasscodePage from 'components/passcode-page/PasscodePage';
import { useInitialQParams } from 'utils/extracte-initial-qparams';
import { useAuthState, useAuthStateEffect } from 'utils/auth-state';
import DefaultPage from 'components/default-page/DefaultPage';
import { ResumableRedirect } from 'components/resumable-redirect/ResumableRedirect';
import { useUserProfile } from 'atoms/user-profile';
import { useRequestLogger, useRouterLogger } from 'utils/router-logger';
import { useThemeSwitcher } from 'utils/theme-switcher';
import { useFlagTraitEffect } from 'utils/flag-traits';
import useInitialOnboardingStep, {
  OnboardingSteps,
} from 'components/onboarding-flow/useInitialOnboardingStep';
import OnboardingContainer from 'components/onboarding-flow/OnboardingContainer';
import { CircularProgress, Stack } from '@mui/material';
import PersonalOnboardingProfileDetails from 'components/onboarding-flow/personal/PersonalOnboardingProfileDetails';
import { useAccessDeniedTracker } from 'utils/access-denied-tracker';
import TroubleshootNetwork from 'components/troubleshoot-network/troubleshoot-network';
import { useTutorial } from 'components/onboarding-tutorials/tutorial-atom';
import { useGlobalButtonTracking } from 'utils/mixpanel';
import { identifyMixpanelUser } from '@cocoplatform/coco-rtc-client';
import { useCommunitySummaries } from 'atoms/community-summaries';
import HomePage from 'components/home-page/HomePage';
import ClassroomCommunitySigninPage from 'components/classroom-community-signin-page/ClasroomCommunitySigninPage';

const EffectsExplorer = React.lazy(
  () => import('components/effects-explorer/EffectsExplorer'),
);

const LocationSetupPage = React.lazy(
  () => import('components/location-setup-page/LocationSetupPage'),
);

const LimitedConsumerEntryForm = React.lazy(
  () =>
    import(
      /* webpackChunkName: "LimitedConsumerEntryForm" */ 'components/limited-consumer-entry-form/LimitedConsumerEntryForm'
    ),
);

const PreLoginNavBar = React.lazy(
  () =>
    import(
      /* webpackChunkName: "PostLoginNavBar" */ 'components/prelogin-nav-bar/PreLoginNavBar'
    ),
);

const CommunityDashboard = React.lazy(
  () =>
    import(
      /* webpackChunkName: "CommunityDashboard" */ 'components/community-dashboard/CommunityDashboard'
    ),
);

const PreLoginLayout = React.lazy(
  () =>
    import(
      /* webpackChunkName: "PreLoginLayout" */ 'components/prelogin-layout/PreLoginLayout'
    ),
);

const LoginKeyJoinPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "LoginKeyJoinPage" */ 'components/login-key-join-page/LoginKeyJoinPage'
    ),
);

const EmailCommunitySignUpPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "EmailCommunitySignUpPage" */ 'components/email-community-signup-page/EmailCommunitySignUpPage'
    ),
);

const EmailCommunitySignInPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "EmailCommunitySignInPage" */ 'components/email-community-signin-page/EmailCommunitySignInPage'
    ),
);

const DashboardLayout = React.lazy(
  () =>
    import(
      /* webpackChunkName: "DashboardLayout" */ 'components/dashboard-layout/DashboardLayout'
    ),
);

const PostLoginNavBar = React.lazy(
  () =>
    import(
      /* webpackChunkName: "PostLoginNavBar" */ 'components/post-login-nav-bar/PostLoginNavBar'
    ),
);

const SharedLayout = React.lazy(
  () =>
    import(
      /* webpackChunkName: "SharedLayout" */ 'components/shared-layout/SharedLayout'
    ),
);

const PreLoginCommunityLayout = React.lazy(
  () =>
    import(
      /* webpackChunkName: "PreLoginCommunityLayout" */ 'components/prelogin-community-layout/PreLoginCommunityLayout'
    ),
);

let SpaceEditorPage: React.FC;

if (ENABLE_SPACE_EDITOR) {
  SpaceEditorPage = React.lazy(
    () =>
      import(
        /* webpackChunkName: "SpaceEditorPage" */ 'components/space-editor-page/SpaceEditorPage'
      ),
  );
}

const CommunityMemberAdder = React.lazy(
  () =>
    import(
      /* webpackChunkName: "CommunityMemberAdder" */ 'components/community-member-adder/CommunityMemberAdder'
    ),
);

const CommunityMembersExplorer = React.lazy(
  () =>
    import(
      /* webpackChunkName: "CommunityMembersExplorer" */ 'components/community-members-explorer/CommunityMembersExplorer'
    ),
);

const SpaceExplorerBody = React.lazy(
  () =>
    import(
      /* webpackChunkName: "SpaceExplorerBody" */ 'components/space-explorer-body/SpaceExplorerBody'
    ),
);

const WelcomePage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "WelcomePage" */ 'components/welcome-page/WelcomePage'
    ),
);

const SignUpPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "SignUpPage" */ 'components/sign-up-page/SignUpPage'
    ),
);

const SignInPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "SignInPage" */ 'components/sign-in-page/SignInPage'
    ),
);

const ProfileUpdatePage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "ProfileUpdatePage" */ 'components/profile-update-page/ProfileUpdatePage'
    ),
);

const CommunitySetupInitPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "CommunitySetupInitPage" */ 'components/community-setup/CommunitySetupInitPage'
    ),
);

const CommunitySetupIntentPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "CommunitySetupIntentPage" */ 'components/community-setup/CommunitySetupIntentPage'
    ),
);

const JoinCodePage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "JoinCodePage" */ 'components/join-code/JoinCodePage'
    ),
);

const PrintableUserList = React.lazy(
  () =>
    import(
      /* webpackChunkName: "PrintableUserList" */ 'components/printable-user-list/PrintableUserList'
    ),
);

const ProfilePage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "ProfilePage" */ 'components/profile-page/ProfilePage'
    ),
);

const PostPasswordResetPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "PostPasswordResetPage" */ 'components/post-password-reset-page/PostPasswordResetPage'
    ),
);

const SpaceDetailsModal = React.lazy(
  () =>
    import(
      /* webpackChunkName: "SpaceDetailsModal" */ 'components/space-details-modal/SpaceDetailsModal'
    ),
);

const JamDetailsModal = React.lazy(
  () =>
    import(
      /* webpackChunkName: "JamDetailsModal" */ 'components/jam-details-modal/JamDetailsModal'
    ),
);

const MediaLibrary = React.lazy(
  () =>
    import(
      /* webpackChunkName: "MediaLibrary" */ 'components/media-library/MediaLibrary'
    ),
);

const PrivacyPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "PrivacyPage" */
      'components/privacy-page/PrivacyPage'
    ),
);

const TermsOfUsePage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "TermsOfUserPage" */
      'components/terms-page/TermsPage'
    ),
);

const EmailUpdatePage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "EmailUpdatePage" */
      'components/email-update-page/EmailUpdatePage'
    ),
);

const SurveyExplorer = React.lazy(
  () =>
    import(
      /* webpackChunkName: "SurveyExplorer" */
      'components/survey-explorer/SurveyExplorer'
    ),
);

const PostUnsubscribePage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "PostUnsubscribePage" */
      'components/post-unsubscribe-page/PostUnsubscribePage'
    ),
);

const CommunitySettings = React.lazy(
  () => import('components/community-settings/CommunitySettings'),
);

const ServerDown = React.lazy(
  () => import('components/server-down/server-down'),
);

const WorldCorridorSpacesAdminPanel = React.lazy(
  () =>
    import(
      /* webpackChunkName: "WorldCorridorAdminPanel" */
      'components/world-corridor-admin-panel/WorldCorridorSpaceAdminPanel'
    ),
);

const WorldCorridorVideosAdminPanel = React.lazy(
  () =>
    import(
      /* webpackChunkName: "WorldCorridorAdminPanel" */
      'components/world-corridor-admin-panel/WorldCorridorVideoAdminPanel'
    ),
);

const WorldCorridorAdminPanelVideoDetails = React.lazy(
  () =>
    import(
      /* webpackChunkName: "WorldCorridorAdminPanel" */
      'components/world-corridor-admin-panel/WorldCorridorVideoDetails'
    ),
);

const WorldCorridorAdminPanelSpaceDetails = React.lazy(
  () =>
    import(
      /* webpackChunkName: "WorldCorridorAdminPanel" */
      'components/world-corridor-admin-panel/WorldCorridorSpaceDetails'
    ),
);

const localSessionId = localStorage.getItem('user-session-id');
export const sessionId =
  localSessionId ?? Math.random().toString(36).substring(2);

if (!localSessionId) {
  localStorage.setItem('user-session-id', sessionId);
}

const RouteHandler: React.FC = () => {
  const { didSetPasscode, savedToken, isProfileUpdated, canEditAcc } =
    useAuthState();
  useInitialQParams();
  useAuthStateEffect();
  useRouterLogger();
  useAccessDeniedTracker();
  useRequestLogger();
  useThemeSwitcher();
  useFlagTraitEffect();
  useGlobalButtonTracking();

  // We care about profile being available because
  // a. userId is used in cache keys
  // b. Through this req. we validate that user is still signed
  //    in and prevent a brief flicker of dashboard before
  //    user is redirected to sign in page
  const { fetchUserProfile, userProfile } = useUserProfile();
  const { communities, syncState, trigger } = useCommunitySummaries({
    autoLoad: false,
  });

  const history = useHistory();
  const location = useLocation();

  const [summariesLoaded, setSummariesLoaded] = React.useState(false);

  useEffect(() => {
    if (!savedToken) return;
    if (!userProfile) {
      fetchUserProfile();
      if (syncState === 'pending') {
        trigger();
      }
    } else {
      identifyMixpanelUser(
        userProfile.id,
        userProfile.firstName || userProfile?.username || '',
        userProfile.email ?? '',
      );
    }

    if (userProfile && !userProfile.onboardingComplete) {
      if (!location.pathname.startsWith('/app/onboarding')) {
        history.push(ROUTES.ONBOARDING);
      }
    }
  }, [savedToken, userProfile, isProfileUpdated, location]);

  useEffect(() => {
    if (syncState === 'loaded') {
      setSummariesLoaded(true);
    }
  }, [syncState]);

  const initialOnboardingStep = useInitialOnboardingStep();
  if (savedToken && (!userProfile || !summariesLoaded)) {
    return (
      <Stack
        width='100vw'
        height='100vh'
        alignItems='center'
        justifyContent='center'
      >
        <CircularProgress />
      </Stack>
    );
  }

  if (REQUIRE_PASSCODE) {
    if (!didSetPasscode) {
      return (
        <Switch>
          <Route exact path={ROUTES.POST_UNSUBSCRIBE}>
            <PostUnsubscribePage />
          </Route>
          <Route exact path={ROUTES.PASSCODE}>
            <PasscodePage />
          </Route>
          <Route exact path={ROUTES.PRIVACY_TERMS}>
            <PrivacyPage />
          </Route>
          <Route exact path={ROUTES.TERMS_OF_USE}>
            <TermsOfUsePage />
          </Route>
          <Route>
            <ResumableRedirect to={ROUTES.PASSCODE} />
          </Route>
          <Route exact path={ROUTES.SERVER_DOWN}>
            <ServerDown />
          </Route>
          <Route exact path={ROUTES.TROUBLESHOOT_NETWORK}>
            <TroubleshootNetwork />
          </Route>
        </Switch>
      );
    }
  }

  if (!PAGES_APP_ENABLED) {
    return (
      <Switch>
        <Route exact path={ROUTES.POST_UNSUBSCRIBE}>
          <PostUnsubscribePage />
        </Route>
        <Route exact path={ROUTES.PRIVACY_TERMS}>
          <PrivacyPage />
        </Route>
        <Route exact path={ROUTES.TERMS_OF_USE}>
          <TermsOfUsePage />
        </Route>
        <Route exact path={ROUTES.SERVER_DOWN}>
          <ServerDown />
        </Route>
        <Route exact path={ROUTES.TROUBLESHOOT_NETWORK}>
          <TroubleshootNetwork />
        </Route>
        {/* / is not served through react-router */}
        <Route>
          <ResumableRedirect to='/' />
        </Route>
      </Switch>
    );
  }

  if (initialOnboardingStep !== OnboardingSteps.ONBOARDING_NOT_REQUIRED) {
    return (
      <Switch>
        <Route exact path={ROUTES.POST_UNSUBSCRIBE}>
          <PostUnsubscribePage />
        </Route>
        <Route exact path={ROUTES.PRIVACY_TERMS}>
          <PrivacyPage />
        </Route>
        <Route exact path={ROUTES.TERMS_OF_USE}>
          <TermsOfUsePage />
        </Route>
        <Route exact path={ROUTES.SIGN_IN}>
          <SignInPage title={true} />
        </Route>
        {/* <Route exact path={ROUTES.SIGN_UP}>
          <PreLoginLayout>
            <SignUpPage />
          </PreLoginLayout>
        </Route> */}
        <Route exact path={ROUTES.LOC_SETUP}>
          <SharedLayout header={<PostLoginNavBar />} wide>
            <LocationSetupPage />
          </SharedLayout>
        </Route>
        <Route exact path={ROUTES.ORG_SETUP}>
          <SharedLayout
            header={<PostLoginNavBar />}
            body={<CommunitySetupIntentPage />}
          />
        </Route>
        <Route exact path={ROUTES.EMAIL_UPDATE}>
          <SharedLayout header={<PostLoginNavBar />}>
            <EmailUpdatePage />
          </SharedLayout>
        </Route>
        <Route exact path={ROUTES.JOIN_CODE}>
          <PreLoginCommunityLayout>
            <JoinCodePage />
          </PreLoginCommunityLayout>
        </Route>
        <Route exact path={ROUTES.JOIN_USER_CODE}>
          <PreLoginCommunityLayout>
            <LoginKeyJoinPage />
          </PreLoginCommunityLayout>
        </Route>
        <Route exact path={ROUTES.JOIN_CODE_WITH_KEY}>
          <PreLoginCommunityLayout>
            <LoginKeyJoinPage />
          </PreLoginCommunityLayout>
        </Route>
        <Route exact path={ROUTES.JOIN_CODE_WITH_EMAIL_SIGNUP}>
          <PreLoginCommunityLayout>
            <EmailCommunitySignUpPage />
          </PreLoginCommunityLayout>
        </Route>
        <Route exact path={ROUTES.JOIN_CODE_WITH_EMAIL_SIGNIN}>
          <PreLoginCommunityLayout>
            <EmailCommunitySignInPage />
          </PreLoginCommunityLayout>
        </Route>
        <Route exact path={ROUTES.JOIN_CODE_WITH_CLASSROOM_SIGNIN}>
          <PreLoginCommunityLayout>
            <ClassroomCommunitySigninPage />
          </PreLoginCommunityLayout>
        </Route>
        {/* <Route exact path={ROUTES.PROFILE_UPDATE}>
          <SharedLayout header={<PreLoginNavBar />}>
            <ProfileUpdatePage />
          </SharedLayout>
        </Route> */}
        {/* <Route exact path={ROUTES.WELCOME}>
          <WelcomePage />
        </Route> */}
        <Route exact path={ROUTES.ONBOARDING}>
          <OnboardingContainer />
        </Route>
        <Route exact path={ROUTES.SERVER_DOWN}>
          <ServerDown />
        </Route>
        <Route exact path={ROUTES.TROUBLESHOOT_NETWORK}>
          <TroubleshootNetwork />
        </Route>
        <Route exact path={ROUTES.ROOT}>
          <HomePage />
        </Route>
        <Route>
          <DefaultPage
            defaultRoute={savedToken ? ROUTES.ONBOARDING : ROUTES.ROOT}
          />
        </Route>
      </Switch>
    );
  }

  if (!userProfile && !LIMITED_CONSUMER_MODE) {
    return null;
  }

  // Invited users don't have an onboarding, they directly go to the community. Therefore
  // We have to show them some intermediate steps before they can go to the community
  // To finish their profile. Note that login key uers do not require finishing profile
  // so they will be able to still bypass this step.
  if (userProfile && userProfile.onboardingComplete && !isProfileUpdated) {
    return (
      <Switch>
        <Route exact path={ROUTES.JOIN_CODE_WITH_KEY}>
          <PreLoginCommunityLayout>
            <LoginKeyJoinPage />
          </PreLoginCommunityLayout>
        </Route>
        <Route exact path={ROUTES.JOIN_CODE_WITH_EMAIL_SIGNUP}>
          <PreLoginCommunityLayout>
            <EmailCommunitySignUpPage />
          </PreLoginCommunityLayout>
        </Route>
        <Route exact path={ROUTES.JOIN_CODE_WITH_EMAIL_SIGNIN}>
          <PreLoginCommunityLayout>
            <EmailCommunitySignInPage />
          </PreLoginCommunityLayout>
        </Route>
        <Route exact path={ROUTES.JOIN_CODE_WITH_CLASSROOM_SIGNIN}>
          <PreLoginCommunityLayout>
            <ClassroomCommunitySigninPage />
          </PreLoginCommunityLayout>
        </Route>
        <Route exact path={ROUTES.PROFILE}>
          <SharedLayout showCorridorPicker medium header={<PostLoginNavBar />}>
            <ProfilePage />
          </SharedLayout>
        </Route>
        <Route exact path={ROUTES.SERVER_DOWN}>
          <ServerDown />
        </Route>
        <Route exact path={ROUTES.TROUBLESHOOT_NETWORK}>
          <TroubleshootNetwork />
        </Route>
        <Route>
          <DefaultPage defaultRoute={ROUTES.PROFILE} />
        </Route>
      </Switch>
    );
  }

  const isSysAdmin = userProfile?.instanceRoles?.includes('SYSADMIN');
  const joinableCommunities = communities.filter(
    (c) => c.currentUserRoles?.length > 0,
  );
  return (
    <Switch>
      {LIMITED_CONSUMER_MODE ? (
        <Route exact path={ROUTES.CORRIDOR_FORM}>
          <LimitedConsumerEntryForm />
        </Route>
      ) : undefined}
      {LIMITED_CONSUMER_MODE ? (
        <Route exact path={ROUTES.SPACE_FORM}>
          <LimitedConsumerEntryForm />
        </Route>
      ) : undefined}
      {LIMITED_CONSUMER_MODE ? (
        <Route exact path={ROUTES.ROOT}>
          <LimitedConsumerEntryForm />
        </Route>
      ) : undefined}
      {!LIMITED_CONSUMER_MODE ? (
        <Route exact path={ROUTES.EMAIL_UPDATE}>
          <SharedLayout
            header={<PostLoginNavBar />}
            backHref={() => {
              history.goBack();
            }}
          >
            <EmailUpdatePage />
          </SharedLayout>
        </Route>
      ) : undefined}
      <Route exact path={ROUTES.POST_UNSUBSCRIBE}>
        <PostUnsubscribePage />
      </Route>
      <Route exact path={ROUTES.PRIVACY_TERMS}>
        <PrivacyPage />
      </Route>
      <Route exact path={ROUTES.TERMS_OF_USE}>
        <TermsOfUsePage />
      </Route>
      <Route exact path={ROUTES.COMMUNITY_DASHBOARD_SETTINGS}>
        <DashboardLayout>
          <CommunitySettings />
        </DashboardLayout>
      </Route>
      <Route exact path={ROUTES.COMMUNITY_DASHBOARD}>
        <DashboardLayout>
          <CommunityDashboard />
        </DashboardLayout>
      </Route>
      <Route exact path={ROUTES.COMMUNITY_DASHBOARD_INVITATION}>
        <DashboardLayout isOnboarding>
          <CommunityDashboard isInvited={true} />
        </DashboardLayout>
      </Route>
      <Route exact path={ROUTES.COMMUNITY_DASHBOARD_SPACE}>
        <DashboardLayout>
          <SpaceExplorerBody />
        </DashboardLayout>
        <Suspense fallback={null}>
          <SpaceDetailsModal />
        </Suspense>
      </Route>
      <Route exact path={ROUTES.JAMS_DASHBOARD}>
        <DashboardLayout>
          <SpaceExplorerBody />
        </DashboardLayout>
        <Suspense fallback={null}>
          <JamDetailsModal />
        </Suspense>
      </Route>
      <Route exact path={ROUTES.COMMUNITY_DASHBOARD_SPACE_EDIT}>
        <DashboardLayout>
          <SpaceExplorerBody />
        </DashboardLayout>
        <Suspense fallback={null}>
          <SpaceDetailsModal isEditing />
        </Suspense>
      </Route>
      <Route exact path={ROUTES.COMMUNITY_DASHBOARD_SPACES}>
        <DashboardLayout>
          <SpaceExplorerBody />
        </DashboardLayout>
      </Route>
      <Route exact path={ROUTES.COMMUNITY_DASHBOARD_NEW_MEMBER}>
        <DashboardLayout>
          <CommunityMemberAdder />
        </DashboardLayout>
      </Route>
      <Route exact path={ROUTES.COMMUNITY_DASHBOARD_MEMBERS}>
        <DashboardLayout>
          <CommunityMembersExplorer />
        </DashboardLayout>
      </Route>
      <Route exact path={ROUTES.MEDIA_LIB_SECTION}>
        <DashboardLayout>
          <MediaLibrary />
        </DashboardLayout>
      </Route>
      <Route exact path={ROUTES.MEDIA_LIB}>
        <DashboardLayout>
          <MediaLibrary />
        </DashboardLayout>
      </Route>
      <Route exact path={ROUTES.DASHBOARD}>
        <Redirect to='/app/dashboard/$all' />
      </Route>
      <Route exact path={ROUTES.JOIN_CODE}>
        <PreLoginCommunityLayout>
          <JoinCodePage />
        </PreLoginCommunityLayout>
      </Route>
      <Route exact path={ROUTES.JOIN_USER_CODE}>
        <PreLoginCommunityLayout>
          <LoginKeyJoinPage />
        </PreLoginCommunityLayout>
      </Route>
      <Route exact path={ROUTES.JOIN_CODE_WITH_KEY}>
        <PreLoginCommunityLayout>
          <LoginKeyJoinPage />
        </PreLoginCommunityLayout>
      </Route>
      <Route exact path={ROUTES.JOIN_CODE_WITH_EMAIL_SIGNUP}>
        <PreLoginCommunityLayout>
          <EmailCommunitySignUpPage />
        </PreLoginCommunityLayout>
      </Route>
      <Route exact path={ROUTES.JOIN_CODE_WITH_EMAIL_SIGNIN}>
        <PreLoginCommunityLayout>
          <EmailCommunitySignInPage />
        </PreLoginCommunityLayout>
      </Route>
      <Route exact path={ROUTES.JOIN_CODE_WITH_CLASSROOM_SIGNIN}>
        <PreLoginCommunityLayout>
          <ClassroomCommunitySigninPage />
        </PreLoginCommunityLayout>
      </Route>
      <Route exact path={ROUTES.JOIN_CODE_POST_COMPLETION}>
        <PreLoginCommunityLayout>
          <JoinCodePage />
        </PreLoginCommunityLayout>
      </Route>
      <Route exact path={ROUTES.PRINTABLE_USER_LIST}>
        <PrintableUserList />
      </Route>
      <Route exact path={ROUTES.SPACE_EDITOR}>
        {ENABLE_SPACE_EDITOR && <SpaceEditorPage />}
      </Route>
      {!LIMITED_CONSUMER_MODE && (
        <Route exact path={ROUTES.PROFILE_UPDATE}>
          <SharedLayout showCorridorPicker header={<PostLoginNavBar />}>
            <ProfilePage />
          </SharedLayout>
        </Route>
      )}
      <Route exact path={ROUTES.JOIN_CODE_PROFILE_UPDATE}>
        <PreLoginCommunityLayout colBodyProps={{ layout: 'narrow' }}>
          <ProfileUpdatePage />
        </PreLoginCommunityLayout>
      </Route>
      <Route exact path={ROUTES.COMMUNITY_SETUP_INIT}>
        <SharedLayout
          header={<PostLoginNavBar />}
          body={<CommunitySetupInitPage />}
        ></SharedLayout>
      </Route>
      <Route exact path={ROUTES.COMMUNITY_SETUP_INTENT}>
        <SharedLayout
          header={<PostLoginNavBar />}
          body={<CommunitySetupIntentPage />}
        />
      </Route>
      <Route exact path={ROUTES.LOC_SETUP}>
        <SharedLayout header={<PostLoginNavBar />} wide>
          <LocationSetupPage />
        </SharedLayout>
      </Route>
      <Route exact path={ROUTES.ORG_SETUP}>
        <SharedLayout
          header={<PostLoginNavBar />}
          body={<CommunitySetupIntentPage />}
        />
      </Route>
      {!LIMITED_CONSUMER_MODE && (
        <Route exact path={ROUTES.PROFILE}>
          <SharedLayout showCorridorPicker medium header={<PostLoginNavBar />}>
            <ProfilePage />
          </SharedLayout>
        </Route>
      )}
      <Route exact path={ROUTES.POST_PASSWORD_RESET}>
        <PostPasswordResetPage />
      </Route>
      {isSysAdmin && (
        <Route exact path={ROUTES.ADMIN_SURVEYS_EXPLORER}>
          <SurveyExplorer />
        </Route>
      )}
      {isSysAdmin && ENABLE_EFFECT_MANAGER && (
        <Route exact path={ROUTES.ADMIN_EFFECTS_EXPLORER}>
          <EffectsExplorer />
        </Route>
      )}
      {isSysAdmin && (
        <Route exact path={ROUTES.WORLD_CORRIDOR_ADMIN_PANEL}>
          <Redirect to={ROUTES.WORLD_CORRIDOR_ADMIN_PANEL_VIDEOS} />
        </Route>
      )}
      {isSysAdmin && (
        <Route exact path={ROUTES.WORLD_CORRIDOR_ADMIN_PANEL_SPACES}>
          <WorldCorridorSpacesAdminPanel />
        </Route>
      )}
      {isSysAdmin && (
        <Route exact path={ROUTES.WORLD_CORRIDOR_ADMIN_PANEL_VIDEOS}>
          <WorldCorridorVideosAdminPanel />
        </Route>
      )}
      {isSysAdmin && (
        <Route exact path={ROUTES.WORLD_CORRIDOR_ADMIN_PANEL_VIDEO_DETAILS}>
          <WorldCorridorAdminPanelVideoDetails />
        </Route>
      )}
      {isSysAdmin && (
        <Route exact path={ROUTES.WORLD_CORRIDOR_ADMIN_PANEL_SPACE_DETAILS}>
          <WorldCorridorAdminPanelSpaceDetails />
        </Route>
      )}
      <Route exact path={ROUTES.SERVER_DOWN}>
        <ServerDown />
      </Route>
      <Route exact path={ROUTES.TROUBLESHOOT_NETWORK}>
        <TroubleshootNetwork />
      </Route>
      <Route>
        <DefaultPage
          defaultRoute={
            LIMITED_CONSUMER_MODE
              ? ROUTES.ROOT
              : communities.length > 0
                ? joinableCommunities.length > 0
                  ? `/app/dashboard/${joinableCommunities[0]?.id}`
                  : `/app/join/${communities[0]?.genericInvitationCode}`
                : ROUTES.DASHBOARD
          }
        />
      </Route>
    </Switch>
  );
};

export default RouteHandler;
