import React, { useEffect, useState } from 'react';

import PersonalOnboardingProfileDetails from 'components/onboarding-flow/personal/PersonalOnboardingProfileDetails';
import EducationalOnboardingProfileDetails from 'components/onboarding-flow/educational/EducationalOnboardingProfileDetails';
import { msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useUserProfile } from 'atoms/user-profile';
import { useHistory } from 'react-router-dom';
import ROUTES from 'constants/routes';

export default function ProfilePage() {
  const { _ } = useLingui();
  const { userProfile } = useUserProfile();

  if (!userProfile?.profileUpdatedAt) {
    return <UserTypeProfilePage />;
  }

  return <EducationalOnboardingProfileDetails buttonText={_(msg`Update`)} />;
}

export function UserTypeProfilePage() {
  const { userProfile } = useUserProfile();
  const history = useHistory();

  if (userProfile?.userType === 'PERSONAL') {
    return (
      <PersonalOnboardingProfileDetails
        disableRedirect
        onSuccess={() => {
          history.push(ROUTES.LANDING);
        }}
      />
    );
  }

  return (
    <EducationalOnboardingProfileDetails
      disableDeactivateAccount
      disableResetPassword
      disableRedirect
      onSuccess={() => {
        history.push(ROUTES.LANDING);
      }}
    />
  );
}
