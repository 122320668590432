import { httpClient } from '@cocoplatform/coco-rtc-client';
import React, { useCallback, useEffect, useState } from 'react';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import { useAuthState } from 'utils/auth-state';
import Button from '@mui/material/Button';
import LC from 'components/prelogin-layout/PreLoginLayout.css';
import CircularProgress from '@mui/material/CircularProgress';
import { reportServerError } from 'utils/report-error';
import { InvitationCodeValidationResult } from '@cocoplatform/coco-rtc-shared';
import UserChip from 'components/user-chip/UserChip';
import C from './JoinCodePage.css';
import { browserStorage } from 'utils/browser-storage';
import { useInvitationCode } from 'atoms/invitation-code-targets';
import { SignUpWizard } from 'components/sign-up-page/SignUpPage';
import FlexRow from 'components/flex/FlexRow';
import CenteredInlineBlock from 'components/flex/CenteredInlineBlock';
import FlexCol from 'components/flex/FlexCol';
import { getQueryParams } from 'utils/url-util';
import CommunityMessageInput from 'components/community-message-input/CommunityMessageInput';
import { useCommunitySummaries } from 'atoms/community-summaries';
import { useCommunityDetails } from 'atoms/community-details';
import { LoadingButton } from '@mui/lab';
import { Box, Stack } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import Swal from 'sweetalert2';
import CocoModal from 'components/coco-modal/coco-modal';
import defaultCoverUrl from '../../assets/images/penguin-group.png';
import _ from 'lodash';
import { Trans, msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useTutorial } from 'components/onboarding-tutorials/tutorial-atom';
import { useUserProfile } from 'atoms/user-profile';
import { GETTING_STARTED_TUTORIAL } from 'components/onboarding-tutorials/getting-started';

export function JoinModal({
  onClose,
  children,
  header,
}: {
  onClose: () => void;
  children: React.ReactNode;
  header: string;
}) {
  return (
    <CocoModal onClose={onClose} header={header}>
      <Box
        p={4}
        pt={3}
        sx={{
          minWidth: {
            xs: '100%',
            sm: '500px',
          },
        }}
      >
        {children}
      </Box>
    </CocoModal>
  );
}

export default function JoinCodePage() {
  const { _ } = useLingui();
  const { code } = useParams<{
    code: string;
  }>();
  const history = useHistory();
  const { codeDetails, loadCodeDetails } = useInvitationCode(code);
  const { isSignedIn } = useAuthState();

  const [communityJoinLinkVisible, setCommunityJoinLinkVisible] =
    useState(false);
  const { reqAccess } = getQueryParams();
  const [message, setMessage] = useState<string>('');

  const [dispatching, setDispatching] = useState(false);

  const { communities, trigger } = useCommunitySummaries({
    autoLoad: false,
  });

  const { fetchUserProfile, userProfile, updateUserProfile } = useUserProfile();

  const { startGettingStartedTutorial } = useTutorial();

  useEffect(() => {
    if (code && codeDetails) {
      if (isSignedIn) {
        if (
          !codeDetails?.invited &&
          !codeDetails?.isGoogleClassroom &&
          codeDetails?.hasLoginKeys
        ) {
          history.push(`/app/join/${code}/loginkey`);
        }
        setCommunityJoinLinkVisible(true);
        return;
      }
    }
  }, [code, codeDetails]);

  const { loadCommunityDetails, community } = useCommunityDetails({
    communityId: codeDetails?.target?.community?.id,
    autoLoad: false,
  });

  if (!isSignedIn && codeDetails?.isValid) {
    if (codeDetails?.hasLoginKeys) {
      return <Redirect to={`/app/join/${code}/loginkey`} />;
    } else {
      return <Redirect to={`/app/join/${code}/email/signin`} />;
    }
  }

  if (communityJoinLinkVisible) {
    if (codeDetails?.isPendingApproval) {
      return (
        <Redirect to={`/app/dashboard/${codeDetails?.target?.community?.id}`} />
      );
    }

    const invited = codeDetails?.invited || codeDetails?.isGoogleClassroom;
    return (
      <JoinModal
        header={
          invited
            ? _(msg`New Community Invitation`)
            : _(msg`Request to Join Community`)
        }
        onClose={() => {
          history.push(`/app/community/setup`);
        }}
      >
        <div className={C.container}>
          <div className={C.containerInner}>
            <Stack alignItems='center'>
              {codeDetails?.target && <InvitationTarget {...{ codeDetails }} />}
              <Box mt={1} />
              {!invited && (
                <CommunityMessageInput
                  message={message}
                  onMessageChange={setMessage}
                />
              )}
              <Box mt={3} />
              {communityJoinLinkVisible && (
                <Stack alignItems='center' direction='row' gap={2}>
                  <LoadingButton
                    variant='contained'
                    sx={{
                      flexBasis: '0',
                      flexGrow: 1,
                      whiteSpace: 'nowrap',
                      paddingLeft: '2.5rem',
                      paddingRight: '2.5rem',
                    }}
                    loading={dispatching}
                    startIcon={invited && <CheckCircleIcon />}
                    onClick={async () => {
                      try {
                        setDispatching(true);
                        const communityId = codeDetails?.target?.community?.id;
                        const response = await httpClient.post(
                          communityId
                            ? `/community/${communityId}/join`
                            : '/community/join',
                          invited
                            ? {
                              code,
                            }
                            : {
                              message,
                            },
                        );
                        // Trigger reload of community summaries to get updated permissions
                        await trigger();
                        await loadCommunityDetails();
                        await fetchUserProfile(); // Instance roles may have changed

                        if (response.data.pendingApproval) {
                          // Trigger reload of code details to confirm request has been submitted.
                          await loadCodeDetails();
                        } else {
                          history.push(`/app/dashboard/${communityId}`);
                          // Check if this was the first community joined
                        }
                      } catch (error) {
                        reportServerError({
                          title: _(msg`Failed to join community`),
                          error,
                        });
                      } finally {
                        setDispatching(false);
                      }
                    }}
                  >
                    {invited ? (
                      <Trans>Accept Invitation</Trans>
                    ) : (
                      <Trans>Request to Join Community</Trans>
                    )}
                  </LoadingButton>
                  {invited && (
                    <LoadingButton
                      startIcon={<CancelIcon />}
                      variant='outlined'
                      sx={{
                        flexBasis: '0',
                        flexGrow: 1,
                      }}
                      onClick={async () => {
                        const { isConfirmed } = await Swal.fire({
                          title: _(
                            msg`Are you sure you want to skip joining this community?`,
                          ),
                          html: _(
                            msg`Once you skip, you will not be able to join this community without a new invitation.`,
                          ),
                          showCancelButton: true,
                        });

                        if (!isConfirmed) {
                          return;
                        }

                        try {
                          setDispatching(true);
                          const communityId =
                            codeDetails?.target?.community?.id;
                          await httpClient.post(
                            `/community/${communityId}/join`,
                            {
                              code,
                              cancel: true,
                            },
                          );
                          // Trigger reload of community summaries to get updated permissions
                          await loadCommunityDetails();

                          history.push(`/app`);
                        } catch (error) {
                          reportServerError({
                            title: _(msg`Failed to skip joining community`),
                            error,
                          });
                        }
                      }}
                    >
                      <Trans>Skip</Trans>
                    </LoadingButton>
                  )}
                </Stack>
              )}
            </Stack>
          </div>
        </div>
      </JoinModal>
    );
  }

  return (
    <CenteredInlineBlock>
      <FlexCol
        alignItems='center'
        className={LC.primaryHeader}
        style={{ marginTop: '50px' }}
      >
        <CircularProgress />
        <div style={{ marginTop: '30px' }}>
          <Trans>Validating code</Trans>: {code}
        </div>
      </FlexCol>
    </CenteredInlineBlock>
  );
}

export function InvitationTarget(p: {
  codeDetails: InvitationCodeValidationResult;
}) {
  const { _ } = useLingui();
  const target = p.codeDetails.target;
  if (!target) return null;

  const { communities } = useCommunitySummaries({
    autoLoad: false,
  });

  const community = communities.find((c) => c.id === target.community?.id);

  return (
    <div style={{ textAlign: 'center' }}>
      {target.community && (
        <>
          <img
            src={target.community.bannerUrl ?? defaultCoverUrl}
            style={{ width: '300px' }}
          />
          <div className={C.primaryHeader} style={{ margin: '0 0 10px 0' }}>
            <Trans>
              Join{' '}
              <span style={{ color: 'var(--color-primary)' }}>
                {target.community.name}
              </span>
              's community on CoCo
            </Trans>
          </div>

          {p.codeDetails?.invited && (
            <CenteredInlineBlock outerStyles={{ marginTop: '30px' }}>
              <FlexRow alignItems='center'>
                {p.codeDetails.creator ? (
                  <UserChip
                    user={p.codeDetails.creator}
                    preMessage={_(msg`You've been invited by`)}
                  />
                ) : (
                  target.community.owners?.map((u) => (
                    <UserChip user={u} key={u.id} postMessage={_(msg`Admin`)} />
                  ))
                )}
              </FlexRow>
            </CenteredInlineBlock>
          )}
        </>
      )}
    </div>
  );
}
