import React, { CSSProperties, ReactChild } from 'react';

const FlexCol = (p: {
  alignItems?: string;
  className?: string;
  style?: CSSProperties;
  children?: ReactChild | ReactChild[];
}) => (
  <div
    className={p.className}
    style={{
      ...p.style,
      display: 'flex',
      flexDirection: 'column',
      alignItems: p.alignItems ?? 'center',
    }}
  >
    {p.children}
  </div>
);

export default FlexCol;
