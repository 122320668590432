import React, { CSSProperties } from 'react';
import { match } from 'ts-pattern';
import { AnyChildren } from 'utils/types';

export interface ColBodyProps {
  layout?: 'narrow' | 'medium' | 'wide';
  className?: string;
  style?: CSSProperties;
  children?: AnyChildren;
}

export default function ColBody(p: ColBodyProps) {
  return (
    <div
      className={p.className}
      style={{
        margin: 'auto',
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
        ...match(p.layout)
          .with('wide', () => ({ maxWidth: '900px' }))
          .with('medium', () => ({ maxWidth: '750px' }))
          .otherwise(() => ({ maxWidth: '460px' })),
        ...p.style,
      }}
    >
      {p.children}
    </div>
  );
}
