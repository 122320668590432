import { createTheme } from '@mui/material/styles';

const palette = {
  success: {
    main: '#0EB050',
    contrastText: '#fff',
  },
  common: { black: '#000', white: '#ffffff' },
  background: { paper: '#fff', default: 'rgba(243, 245, 240, 1)' },
  primary: {
    light: '#F9F7FF',
    main: '#986CFC',
    dark: '#7347D1',
    contrastText: '#fff',
  },
  secondary: {
    light: '#faf6fe', // #ffab1a
    main: '#faf6fe', // #ff8c1a
    dark: '#faf6fe', // #ff661a
    contrastText: '#47365e',
  },
  error: {
    light: '#e57373',
    main: '#f44336',
    dark: '#d32f2f',
    contrastText: '#fff',
  },
  /* text: {
    primary: 'rgba(255, 255, 255, 1)',
    secondary: '#575e75',
    disabled: 'rgba(0, 0, 0, 0.38)',
    // hint: 'rgba(0, 0, 0, 0.38)',
  }, */
};

export const theme = createTheme({
  palette,
  components: {
    MuiInputBase: {
      variants: [
        {
          props: {},
          style: {
            backgroundColor: palette.primary.light,
          },
        },
      ],
    },
    MuiTextField: {
      variants: [
        {
          props: { variant: 'outlined' },
          style: {
            // backgroundColor: palette.primary.light,
            fieldset: {
              borderColor: palette.primary.main,
            },
          },
        },
      ],
    },
    MuiAutocomplete: {
      variants: [
        {
          props: {},
          style: {
            '& [class*="MuiFilledInput-root"]': {
              paddingTop: 9,
              paddingBottom: 9,
            },
          },
        },
      ],
    },
    MuiButton: {
      variants: [
        // {
        //   props: { variant: 'contained', color: 'primary' },
        //   style: {
        //     fontWeight: 'var(--font-weight-bold)',
        //   },
        // },
        {
          props: { variant: 'contained', color: 'secondary' },
          style: {
            ':hover': {
              backgroundColor: 'var(--color-selected)',
            },
          },
        },
      ],
    },
  },
  typography: {
    fontFamily: `"Nunito", "Helvetica Neue", "Helvetica", "Arial", "sans-serif"`,
    allVariants: {
      color: 'var(--color-text)',
    },
    h1: {
      fontSize: '2rem',
      fontWeight: 'var(--font-weight-bold)',
      color: 'var(--color-text)',
      //     font-weight: var(--font-weight-bold);
      // color: var(--color-text);
      // text-align: center;
    },
    h2: {
      fontSize: '1.6rem',
      fontWeight: 'var(--font-weight-bold)',
      color: 'var(--color-text)',
      //     font-weight: var(--font-weight-bold);
      // color: var(--color-text);
      // text-align: center;
    },
    h3: {
      fontSize: '1.2rem',
      fontWeight: 'var(--font-weight-bold)',
      color: 'var(--color-text)',
      //     font-weight: var(--font-weight-bold);
      // color: var(--color-text);
      // text-align: center;
    },
    h4: {
      fontSize: '1.1rem',
      fontWeight: 'var(--font-weight-bold)',
      color: 'var(--color-text)',
      //     font-weight: var(--font-weight-bold);
      // color: var(--color-text);
      // text-align: center;
    },
    body1: {
      fontSize: '1rem',
      color: 'var(--color-text)',
      //     font-weight: var(--font-weight-bold);
      // color: var(--color-text);
      // text-align: center;
    },
    subtitle1: {
      fontSize: '1.1rem',
      color: 'var(--color-subtext)',
      // lineHeight: '1.5rem'
    },
    subtitle2: {
      fontSize: '0.9rem',
      color: 'var(--color-subtext)',
    },
    caption: {
      fontSize: '0.8rem',
      color: 'var(--color-subtext)',
      // lineHeight: '1.5rem'
    },
  },
});
