import React, { useEffect } from 'react';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import { useAuthState } from 'utils/auth-state';
import { useInvitationCode } from 'atoms/invitation-code-targets';
import SignIn from 'components/sign-in/SignIn';
import {
  BackToLoginKeyJoinPageLink,
  MoreSigninOptions,
} from 'components/login-key-join-page/LoginKeyJoinPage';
import { InvitationTarget } from 'components/join-code/JoinCodePage';
import ProfileUpdatePage from 'components/profile-update-page/ProfileUpdatePage';
import { Box } from '@mui/material';
import EducationalOnboardingProfileDetails from 'components/onboarding-flow/educational/EducationalOnboardingProfileDetails';
import { useCommunityDetails } from 'atoms/community-details';
import PersonalOnboardingProfileDetails from 'components/onboarding-flow/personal/PersonalOnboardingProfileDetails';
import { UserTypeProfilePage } from 'components/profile-page/ProfilePage';
import { Trans } from '@lingui/macro';

export default function ClassroomCommunitySigninPage() {
  const { code } = useParams<{
    code: string;
  }>();
  const history = useHistory();
  const { codeDetails, loadCodeDetails } = useInvitationCode(code);
  const { isSignedIn, isProfileUpdated } = useAuthState();
  const { loadCommunityDetails, community } = useCommunityDetails({
    communityId: codeDetails?.target?.community?.id,
  });

  useEffect(() => {
    if (isSignedIn && isProfileUpdated) {
      if (codeDetails?.target?.community) {
        Promise.all([loadCodeDetails(), loadCommunityDetails()]).then(() => {
          history.push(`/app/join/${code}`);
        });
      } else {
        history.push(`/app`);
      }
    }
  }, [isSignedIn, isProfileUpdated]);

  return (
    <>
      {!(isSignedIn && !isProfileUpdated) && <MoreSigninOptions code={code} />}
      <Box mt={6} />
      <div style={{ maxWidth: '460px', margin: 'auto', marginTop: '1rem' }}>
        {codeDetails?.target && !isSignedIn && (
          <InvitationTarget {...{ codeDetails }} />
        )}
        {!isSignedIn && (
          <SignIn title={false} onSuccess={() => {}} classroom={true} />
        )}
        {isSignedIn && !isProfileUpdated && (
          <>
            <UserTypeProfilePage />
          </>
        )}
      </div>
    </>
  );
}
