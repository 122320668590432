/* eslint-disable lingui/no-unlocalized-strings */
import { CocoLogger } from '@cocoplatform/coco-logger';
import { httpClient } from '@cocoplatform/coco-rtc-client';
import AccessDeniedModal from 'components/access-denied-modal/access-denied-modal';
import { useModal } from 'mui-modal-provider';
import { useEffect } from 'react';

export const useAccessDeniedTracker = () => {
  const { showModal } = useModal();

  useEffect(() => {
    httpClient.interceptors.response.use(undefined, async (error) => {
      // get response data
      const response = error.response;

      if (
        response?.data?.error?.includes(
          'Your account has not yet been approved',
        ) ||
        response?.data?.error?.includes(
          "This email hasn't been approved for use",
        )
      ) {
        const modal = showModal(AccessDeniedModal, {
          onClose: () => {
            modal.destroy();
          },
          email: response?.data?.error?.split(':')[1]?.trim(),
        });
        return;
      }

      return Promise.reject(error);
    });
  }, []);
};
