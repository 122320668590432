import PreLoginNavBar from 'components/prelogin-nav-bar/PreLoginNavBar';
import React, { useEffect } from 'react';
import {
  Box,
  List,
  ListItem,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material';
import { maxWidth } from '@mui/system';
import { width } from 'dom7';
import { Trans, msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';

export default function TroubleshootNetwork() {
  const { _ } = useLingui();

  return (
    <>
      <Stack width='100vw' height='100vh' justifyContent='space-between'>
        <PreLoginNavBar />
        <Stack alignItems='center' pb={2}>
          <Stack maxWidth={600}>
            <Typography color='primary' mt={4} variant='h1'>
              <Trans>
                Configuring your network to make CoCo work correctly:
              </Trans>
            </Typography>
            <Typography mt={3} variant='body1'>
              <Trans>
                The following is a list of IP addresses/domains that need to be
                allowed for CoCo to work correctly on your machine. This list
                has been last updated on July 31, 2023 and may change in the
                future.
              </Trans>
            </Typography>
            <Typography mt={4} variant='h3'>
              <Trans>CoCo's frontend webpage and API servers:</Trans>
            </Typography>
            <Typography variant='body1'>
              <ol>
                <li>
                  <Trans>coco.build</Trans>
                </li>
                <li>
                  <Trans>*.coco.build</Trans>
                </li>
              </ol>
              <Trans>
                Apart from these domains, CoCo also requires whitelisting IP
                addresses for certain functionality to work correctly:
              </Trans>
            </Typography>
            <Typography mt={4} variant='h3'>
              <Trans>AWS IP Ranges</Trans>
            </Typography>
            <Typography variant='body1'>
              <Trans>
                CoCo uses AWS Cloudfront, and other AWS services to serve
                assets, and also route video/audio streams between creators on
                the platform. It is highly recommended to allow AWS's IP range
                for best support to most functionality on Coco. An updated list
                of AWS IP ranges is available here: (
                <a href='https://ip-ranges.amazonaws.com/ip-ranges.json'>
                  https://ip-ranges.amazonaws.com/ip-ranges.json
                </a>
                )
              </Trans>
            </Typography>
            <Typography mt={4} variant='h3'>
              <Trans>Twilio STUN/TURN servers</Trans>
            </Typography>
            <Typography variant='body1'>
              In certain cases, CoCo may attempt to route media through Twilio's
              Edge servers when it is more efficient to do so, or due to other
              network restrictions for media traffic. For this to work
              correctly, the twilio IP ranges need to be allowed on the machine:
              <a href='https://www.twilio.com/docs/stun-turn/regions'>
                https://www.twilio.com/docs/stun-turn/regions
              </a>
            </Typography>
            <Typography mt={4} variant='body2'>
              Note: In some cases, it may not be plausible to allow AWS/Twilio's
              entire IP range due to security restrictions. In this case, please
              reach out to us at{' '}
              <a href='mailto:hello@coco.build'>hello@coco.build</a>, and we
              will try to figure out a solution which works best for your
              network environment.
            </Typography>
          </Stack>
        </Stack>
        <Box></Box>
      </Stack>
    </>
  );
}
