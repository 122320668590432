import type { User } from '@cocoplatform/coco-rtc-shared';
import FlexCol from 'components/flex/FlexCol';
import UserAvatar from 'components/user-avatar/UserAvatar';
import React from 'react';

export default function UserChip(p: {
  user: User;
  preMessage?: string;
  postMessage?: string;
}) {
  return (
    <div style={{ display: 'inline-block', marginLeft: '0.5rem' }}>
      <FlexCol alignItems='center'>
        <UserAvatar
          user={p.user}
          style={{
            border: '2px solid var(--color-border)',
          }}
        />
        <div
          style={{
            color: 'var(--color-subtext)',
            fontSize: 'var(--font-size-subtext)',
            textAlign: 'center',
            paddingTop: '5px',
          }}
        >
          {p.preMessage && <div>{p.preMessage}</div>}
          <div>
            {p.user.firstName} {p.user.lastName}
          </div>
          {p.postMessage && <div>{p.postMessage}</div>}
        </div>
      </FlexCol>
    </div>
  );
}
