import React, { CSSProperties } from 'react';
import C from './Separator.css';
import cc from 'classcat';

export default function Separator(p: {
  style?: CSSProperties;
  label: string;
  className?: string;
}) {
  return (
    <div className={cc([C.sepContainer, p.className])} style={p.style}>
      <div className={C.sep} />
      <div className={C.sepLabel}>{p.label}</div>
    </div>
  );
}
