import { ChangeEventHandler } from 'react';

export const limitChangeLength =
  (
    maxLength: number,
    onChange: ChangeEventHandler<HTMLInputElement>,
  ): ChangeEventHandler<HTMLInputElement> =>
  (e) => {
    const { value } = e.target;
    if (value.length <= maxLength) {
      onChange(e);
    }
  };
