import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import React, { useState } from 'react';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';

export default function PasswordField(p: Omit<TextFieldProps, 'type'>) {
  const [passwordVisible, setPasswordVisible] = useState(false);
  return (
    <TextField
      {...p}
      type={passwordVisible ? 'text' : 'password'}
      InputProps={{
        ...p.InputProps,
        endAdornment: (
          <InputAdornment position='end'>
            <IconButton
              style={{ color: 'var(--color-subtext)' }}
              onClick={() => {
                setPasswordVisible((s) => !s);
              }}
            >
              {passwordVisible ? (
                <VisibilityOffOutlinedIcon />
              ) : (
                <VisibilityOutlinedIcon />
              )}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
}
