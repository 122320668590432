import cc from 'classcat';
import React from 'react';
import TextField from '@mui/material/TextField';
import FormHelperText from '@mui/material/FormHelperText';
import { limitChangeLength } from 'utils/form';
import C from './CommunityMessageInput.css';
import { useLingui } from '@lingui/react';
import { Trans, msg } from '@lingui/macro';

export default function CommunityMessageInput(p: {
  message: string;
  maxContentLen?: number;
  placeholder?: string;
  onMessageChange: (message: string) => void;
}) {
  const { _ } = useLingui();
  const maxContentLen = p.maxContentLen ?? 200;
  return (
    <div className={C.messageInputContainer}>
      <TextField
        className={cc([C.ghostControl, C.messageInput])}
        multiline
        fullWidth
        value={p.message}
        onChange={limitChangeLength(maxContentLen, (e) => {
          p.onMessageChange(e.target.value);
        })}
        sx={{
          minWidth: {
            xs: '100%',
            sm: '400px',
          },
        }}
        rows={10}
        placeholder={[
          _(msg`Add message (optional)`),
          '',
          p.placeholder ||
          _(
            msg`Make it easier for the community admin to approve your request. Share more about you and why you want to join this community.`,
          ),
        ].join('\n')}
      />
      <FormHelperText
        style={{
          textAlign: 'right',
          color: 'var(--color-subtext)',
          fontStyle: 'italic',
        }}
      >
        <Trans>
          {Math.max(maxContentLen - p.message.length, 0)} chars remaining
        </Trans>
      </FormHelperText>
    </div>
  );
}
