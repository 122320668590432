import { atom, useRecoilState } from 'recoil';
import { InvitationCodeValidationResult } from '@cocoplatform/coco-rtc-shared';
import { reportServerError } from 'utils/report-error';
import { httpClient } from '@cocoplatform/coco-rtc-client';
import { useCommunitySummaries } from 'atoms/community-summaries';
import { useHistory } from 'react-router-dom';
import { msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';

const invitationCodesAtom = atom({
  key: 'invitationCodeTargets',
  default: {} as Record<string, InvitationCodeValidationResult>,
});

export const useInvitationCode = (
  code: string | null,
  opts?: {
    onError?: typeof reportServerError;
  },
) => {
  const { _ } = useLingui();
  const [invitationCodeTargets, setInvitationCodeTargets] =
    useRecoilState(invitationCodesAtom);

  const history = useHistory();

  const onError = opts?.onError ?? reportServerError;

  async function loadCodeDetails(newCode = code) {
    if (!newCode) return;
    try {
      const resp = await httpClient.get(`/code/${newCode}/validate`);
      if (!resp.data.isValid) {
        onError({
          title: _(msg`This code is not valid.`),
          text: _(msg`It may be incorrect or may have expired.`),
        });
        return;
      }
      if (!resp.data.isAvailable) {
        // This means that the code is valid, but we already have an active role in the community.
        // Just redirect to the community.
        history.push(
          `/app/dashboard/${resp.data.target?.community?.id}/spaces`,
        );
        return;
      }
      if (resp.data.target) {
        setInvitationCodeTargets((targets) => ({
          ...targets,
          [newCode]: resp.data,
        }));
      }

      return resp.data;
    } catch (error) {
      onError({
        title: _(msg`Failed to validate code`),
        error,
      });
    }
  }

  return {
    codeDetails: code ? invitationCodeTargets[code] : null,
    loadCodeDetails,
  };
};
