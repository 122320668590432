import { ThemeProvider } from '@mui/material/styles';
import { GoogleOAuthProvider } from '@react-oauth/google';
import React, { Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import { theme } from 'styles/theme';
import { FlagsmithProvider } from 'flagsmith/react';
import flagsmith from 'flagsmith';
import RouteHandler from './routes';
import FullPageLoaderFallback from 'components/full-page-loader-fallback/FullPageLoaderFallback';
import NetworkGuard from 'components/network-guard/NetworkGuard';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import ModalProvider from 'mui-modal-provider';
import LocaleProvider from 'components/locale-switcher/LocaleProvider';
import Joyride from 'react-joyride';
import { JoyrideWrapper } from 'components/onboarding-tutorials/tutorial-atom';

const msalInstance = new PublicClientApplication({
  auth: {
    clientId: '4600334a-4f7d-431c-be71-08529e04f265',
    redirectUri: window.location.origin,
  },
});

declare const GOOGLE_CLIENT_ID: string;
declare const FLAGSMITH_ENV_ID: string;

const App = (): React.ReactElement => {
  return (
    <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
      <MsalProvider instance={msalInstance}>
        <FlagsmithProvider
          options={{
            environmentID: FLAGSMITH_ENV_ID,
          }}
          flagsmith={flagsmith}
        >
          <ThemeProvider theme={theme}>
            <RecoilRoot>
              <LocaleProvider>
                <NetworkGuard>
                  <BrowserRouter>
                    <ModalProvider>
                      <Suspense fallback={<FullPageLoaderFallback />}>
                        <JoyrideWrapper />
                        <RouteHandler />
                      </Suspense>
                    </ModalProvider>
                  </BrowserRouter>
                </NetworkGuard>
              </LocaleProvider>
            </RecoilRoot>
          </ThemeProvider>
        </FlagsmithProvider>
      </MsalProvider>
    </GoogleOAuthProvider>
  );
};

export default App;
