import React, { FormEvent, useState, useEffect } from 'react';
import { httpClient } from '@cocoplatform/coco-rtc-client';
import swal from 'sweetalert';
import CodeInput from './CodeInput';

import C from './CodeForm.css';
import EmailProviderLinks from './EmailProviderLinks';
import FormHelperText from '@mui/material/FormHelperText';
import TextField from '@mui/material/TextField';
import ControlLabel from 'components/control-label/ControlLabel';
import Grid from '@mui/material/Grid';
import { PasswordHints } from 'components/profile-update-page/ProfileUpdatePage';
import { PASSWORD_REGEX } from 'utils/validation';
import LoadingButton from '@mui/lab/LoadingButton';
import Stack from '@mui/material/Stack';
import { Box, Link, Typography } from '@mui/material';
import { reportServerError } from 'utils/report-error';
import { Trans, msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';

export default function PasswordResetForm(p: {
  onSuccess: () => void;
  email: string;
}) {
  const { _ } = useLingui();
  const [code, setCode] = useState<string | null>(null);
  const [password, setPassword] = useState<string>('');
  const [showResend, setShowResend] = useState(false);
  const [passwordConfirmation, setPasswordConfirmation] = useState<string>('');
  const [isDispatching, setDispatching] = useState(false);

  const [rerender, setRerender] = useState(0);

  const passwordConfirmationMismatch =
    password && passwordConfirmation && password !== passwordConfirmation;

  return (
    <Stack
      component='form'
      onSubmit={handleSubmit}
      sx={{
        minWidth: {
          xs: '100%',
          sm: '400px',
        },
      }}
      autoComplete='off'
      alignItems='center'
      mt={4}
    >
      <Typography variant='h3' textAlign='center'>
        <Trans>Check your email for the password reset code</Trans>
      </Typography>
      <Box mt={2} />
      <div
        style={{
          textAlign: 'center',
          color: 'var(--color-subtext)',
        }}
      >
        <Trans>
          We've sent a 6-letter code to
          <br />
          <span style={{ fontWeight: 'var(--font-weight-bold)' }}>
            {p.email ?? _(msg`your email`)}
          </span>
          .
          <Box mt={3} />
          <div>The code expires shortly, so please enter it soon.</div>
        </Trans>
      </div>
      <EmailProviderLinks style={{ marginBottom: 0 }} />
      <CodeInput
        key={rerender}
        onChange={({ value }) => {
          setCode(value);
        }}
        required
      />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ControlLabel htmlFor='password-field'>
            <Trans>New Password</Trans>
          </ControlLabel>
          <TextField
            value={password}
            id='password-field'
            name='password'
            type='password'
            autoComplete='new-password'
            onChange={(e) => setPassword(e.currentTarget.value)}
            variant='filled'
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12}>
          <ControlLabel htmlFor='password-confirm-field'>
            <Trans>Confirm New Password</Trans>
          </ControlLabel>
          <TextField
            value={passwordConfirmation}
            type='password'
            name='password_confirmation'
            required
            autoComplete='off'
            onChange={(e) => setPasswordConfirmation(e.currentTarget.value)}
            id='password-confirm-field'
            variant='filled'
            fullWidth
            style={{ margin: '0 0 1rem 0' }}
          />
        </Grid>
        <FormHelperText>
          <PasswordHints password={password} />
        </FormHelperText>
        <Grid item xs={12}>
          <LoadingButton
            size='large'
            fullWidth
            variant='contained'
            color='primary'
            type='submit'
            loading={isDispatching}
          >
            <Trans>Update</Trans>
          </LoadingButton>
        </Grid>
      </Grid>
      {passwordConfirmationMismatch && (
        <FormHelperText error>
          <Trans>Passwords don't match</Trans>
        </FormHelperText>
      )}
      <div className={C.spamHint}>
        <Trans>Can't find your code? Check your spam folder!</Trans>
      </div>
      {showResend && (
        <Box textAlign='center'>
          <Typography variant='body1'>
            <Trans>
              <Link
                onClick={async () => {
                  await submitPasswordResetRequest();
                  setShowResend(false);
                  setRerender((prev) => prev + 1);
                }}
              >
                Click here
              </Link>{' '}
              to receive a new code.
            </Trans>
          </Typography>
        </Box>
      )}
    </Stack>
  );

  async function dispatchToken() {
    setDispatching(true);
    try {
      const { data } = await httpClient.post('/auth/password/reset', {
        email: p.email,
        code,
        password,
      });
      if (data.success) {
        await swal({
          icon: 'success',
          title: _(msg`Password reset successful`),
          text: _(msg`You can login with updated password`),
        });
        p.onSuccess?.();
      } else {
        handleRespErr(data);
      }
    } catch (e: any) {
      handleRespErr(e.response.data);
      setShowResend(true);
    } finally {
      setDispatching(false);
    }
  }

  function handleRespErr(resp?: { error: string }) {
    swal({
      title: resp?.error ?? _(msg`Something went wrong`),
      icon: false as any,
      dangerMode: true,
    });
  }

  async function handleSubmit(e: FormEvent<HTMLFormElement>) {
    e.stopPropagation();
    e.preventDefault();
    if (!code || !password || !passwordConfirmation) return;
    if (password !== passwordConfirmation) {
      alert(_(msg`Passwords don't match`));
      return;
    }
    if (!password.match(PASSWORD_REGEX)) {
      alert(_(msg`Password is not strong enough`));
      return;
    }
    dispatchToken();
  }

  async function submitPasswordResetRequest() {
    try {
      await httpClient.post('/auth/password/reset/request', {
        email: p.email,
      });
    } catch (error: any) {
      reportServerError({
        title: _(msg`Failed to reset password`),
        error,
      });
    }
  }
}
