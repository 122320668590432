import React, {
  CSSProperties,
  useState,
  useMemo,
  FormEvent,
  useEffect,
} from 'react';
import CodeForm from 'components/code-form/CodeForm';
import ROUTES from 'constants/routes';
import { useHistory } from 'react-router-dom';
import { httpClient } from '@cocoplatform/coco-rtc-client';
import TextField, { FilledTextFieldProps } from '@mui/material/TextField';
import LoadingButton from '@mui/lab/LoadingButton';
import { EMAIL_REGEX } from 'utils/validation';
import FormHelperText from '@mui/material/FormHelperText';
import Separator from 'components/prelogin-layout/Separator';
import { useAuthState } from 'utils/auth-state';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import PasswordResetForm from 'components/code-form/PasswordResetForm';
import InputAdornment from '@mui/material/InputAdornment';
import { reportServerError } from 'utils/report-error';
import StorageOptionControl from 'components/storage-option-control/StorageOptionControl';
import CenteredInlineBlock from 'components/flex/CenteredInlineBlock';
import * as qs from 'qs';
import { browserStorage } from 'utils/browser-storage';
import { Box } from '@mui/material';
import C from './SignInPage.css';
import SocialLoginButtons from 'components/social-login-buttons/SocialLoginButtons';
import { Trans, msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { EVENTS, INTERACTIONS, useTrack } from 'utils/mixpanel';

export default function SignIn(p: {
  title?: boolean;
  classroom?: boolean;
  signUpLink?: string;
  onSuccess?: () => void;
  style?: CSSProperties;
  InputProps?: FilledTextFieldProps['InputProps'];
}) {
  const { _ } = useLingui();
  const { saveToken, setProfileCompletionState } = useAuthState();
  const [dispatchedCodeType, setDispatchedCodeType] = useState<
    'otp' | 'reset' | null
  >(null);
  const history = useHistory();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [isDispatchingPrimary, setDispatchingPrimary] = useState(false);
  const [isDispatchingSecondary, setDispatchingSecondary] = useState(false);
  const [isTouched, setTouched] = useState(false);
  const [passwordError, setPasswordError] = useState('');
  const [keepSignedIn, setKeepSignedIn] = useState(DEFAULT_PERSIST_SESSION);

  const track = useTrack();

  const isEmailValid = useMemo(() => {
    return !isTouched || EMAIL_REGEX.test(email);
  }, [email]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    if (queryParams.has('email')) {
      setEmail(queryParams.get('email')!);
      queryParams.delete('email');
      history.replace({
        search: queryParams.toString(),
      });
    }
  }, []);

  return (
    <>
      {dispatchedCodeType === 'otp' ? (
        <CodeForm
          email={email}
          onSuccess={async () => {
            p.onSuccess?.();
            browserStorage.selectStorage(keepSignedIn);
            // history.push(ROUTES.HOME);
          }}
        />
      ) : dispatchedCodeType === 'reset' ? (
        <PasswordResetForm
          email={email}
          onSuccess={() => {
            p.onSuccess?.();
            setDispatchedCodeType(null);
          }}
        />
      ) : isDispatchingSecondary ? (
        <div>
          <CircularProgress />
        </div>
      ) : (
        <form onSubmit={submitPassword} autoComplete='off'>
          {p.title ? (
            <h1 style={{ textAlign: 'center' }}>
              <Trans>Sign in</Trans>
            </h1>
          ) : (
            <Box mb={3}></Box>
          )}
          {ENABLE_SOCIAL_LOGIN && (
            <>
              <Box mt={6} />
              <SocialLoginButtons
                onSuccess={async ({ accessToken, provider }) => {
                  try {
                    const { data } = await httpClient.get(
                      `/auth/oauth/verification?${qs.stringify({
                        accessToken,
                        provider,
                        userType: 'EDUCATIONAL',
                        intent: 'login',
                      })}`,
                    );
                    saveToken(data.token, true);
                    browserStorage.selectStorage(keepSignedIn);
                  } catch (err: any) {
                    if (
                      err?.response?.data?.error?.includes(
                        'Email not registered',
                      )
                    ) {
                      reportServerError({
                        title: _(
                          msg`You haven't registered for CoCo. Please sign up and create an account first!`,
                        ),
                      });
                      if (p.signUpLink) {
                        history.push(p.signUpLink);
                      }
                    }
                    console.error(err);
                  }
                }}
              />
              <Box mt={2} />
            </>
          )}
          {ENABLE_SOCIAL_LOGIN && !p.classroom && <Separator label='or' />}
          {/*<ControlLabel htmlFor='email_control'>Email</ControlLabel>*/}
          {!p.classroom && (
            <>
              <TextField
                value={email}
                name='email'
                id='email_control'
                onBlur={() => setTouched(false)}
                onChange={(e) => setEmail(e.currentTarget.value)}
                type='email'
                placeholder='Enter your email'
                variant='filled'
                autoFocus={true}
                fullWidth
                required
                error={!isEmailValid}
                style={{
                  borderRadius: '4px',
                  border: '1px solid var(--color-primary)',
                  ...p.style,
                }}
                InputProps={{
                  style: {
                    background: 'var(--color-base)',
                    color: 'var(--color-text)',
                    ...p.InputProps?.style,
                  },
                  onFocus: () => {
                    track(EVENTS.EMAIL_INPUT, INTERACTIONS.FOCUS);
                  },
                }}
              />
              {isTouched && !email && (
                <FormHelperText error>
                  <Trans>Please enter an email address</Trans>
                </FormHelperText>
              )}
              {!isEmailValid && (
                <FormHelperText error>
                  <Trans>This email does not look valid</Trans>
                </FormHelperText>
              )}
              {/*<ControlLabel
            style={{ marginTop: '0.5rem' }}
            htmlFor='password_control'
          >
            Password
          </ControlLabel>*/}
              <TextField
                value={password}
                id='password_control'
                type={showPassword ? 'text' : 'password'}
                placeholder='password'
                onChange={(e) => setPassword(e.currentTarget.value)}
                variant='filled'
                fullWidth
                style={{
                  marginTop: '1rem',
                  borderRadius: '4px',
                  border: '1px solid var(--color-primary)',
                  ...p.style,
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <Button
                        variant='text'
                        size='small'
                        onClick={() => {
                          setShowPassword((s) => !s);
                        }}
                      >
                        {showPassword ? (
                          <Trans>hide</Trans>
                        ) : (
                          <Trans>show</Trans>
                        )}
                      </Button>
                    </InputAdornment>
                  ),
                  style: {
                    background: 'var(--color-base)',
                    color: 'var(--color-text)',
                    ...p.InputProps?.style,
                  },
                  onFocus: () => {
                    track(EVENTS.PASSWORD_INPUT, INTERACTIONS.FOCUS);
                  },
                }}
              />
              {passwordError ? (
                <FormHelperText error>{passwordError}</FormHelperText>
              ) : null}
              <Button
                variant='text'
                onClick={submitPasswordResetRequest}
                sx={{ fontSize: 'var(--font-size-subtext)' }}
                data-analytics={EVENTS.FORGOT_PASSWORD}
              >
                <Trans>Forgot Password?</Trans>
              </Button>

              <LoadingButton
                style={{
                  margin: '1rem 0',
                  fontWeight: 'var(--font-weight-bold)',
                }}
                loading={isDispatchingPrimary}
                size='large'
                fullWidth
                variant='contained'
                color='primary'
                type='submit'
                data-analytics={EVENTS.CONTINUE}
              >
                <Trans>Continue</Trans>
              </LoadingButton>
              <LoadingButton
                style={{
                  marginBottom: '0.5rem',
                  fontWeight: 'var(--font-weight-bold)',
                }}
                size='large'
                fullWidth
                variant='contained'
                color='primary'
                type='submit'
                onClick={submitCode}
                data-analytics={EVENTS.CONTINUE_WITHOUT_PASSWORD}
              >
                <Trans>Continue without password</Trans>
              </LoadingButton>
            </>
          )}
          <CenteredInlineBlock>
            <StorageOptionControl
              checked={keepSignedIn}
              onChange={(v) => {
                setKeepSignedIn(v);
              }}
            />
          </CenteredInlineBlock>
          {p.signUpLink === undefined ? null : (
            <div className={C.altVariantLink}>
              <Trans>Do not have an account?</Trans>{' '}
              <a
                data-analytics={EVENTS.ALT_SIGN_UP}
                onClick={async () => {
                  history.push(p.signUpLink as string);
                }}
              >
                <Trans>Sign up</Trans>
              </a>
            </div>
          )}
          {/*<Separator label='or' />
          <div style={{ margin: '1rem 0' }}>
            <Button
              variant='text'
              onClick={submitCode}
              style={{ textTransform: 'none' }}
            >
              Skip password, get a code on email to sign in
            </Button>
            </div>*/}
        </form>
      )}
    </>
  );

  async function submitCode() {
    setTouched(true);
    if (!email) return;
    setDispatchingSecondary(true);
    try {
      const res = await httpClient.post('/auth/login-code', {
        email,
        intent: 'login',
      });
      if (res?.data.success) {
        setDispatchedCodeType('otp');
      }
    } catch (error: any) {
      if (
        error.response?.data?.error ===
        "You haven't registered for CoCo. Please sign up and create an account first!"
      ) {
        reportServerError({
          title: _(
            msg`You haven't registered for CoCo. Please sign up and create an account first!`,
          ),
        });
        if (p.signUpLink) {
          history.push(p.signUpLink);
        }
        return;
      }
      reportServerError({
        title: _(msg`Failed to verify login code`),
        error,
      });
    } finally {
      setDispatchingSecondary(false);
    }
  }

  async function submitPassword(e: FormEvent<HTMLFormElement>) {
    e.stopPropagation();
    e.preventDefault();
    setTouched(true);
    if (!password || !email) return;
    setPasswordError('');
    browserStorage.selectStorage(keepSignedIn);
    try {
      setDispatchingPrimary(true);
      const { data } = await httpClient.post('/auth/password/verification', {
        email,
        password,
      });

      if (data.success) {
        if (data.profileComplete) {
          setProfileCompletionState('COMPLETE');
        }
        saveToken(data.token, true);

        // if (data.profileComplete) {
        //   const submissionResult = await submitPostLoginInvite();
        //   if (submissionResult?.inviteCode) {
        //     history.push(`/app/join/${submissionResult.inviteCode}`);
        //     return;
        //   }
        // }

        p.onSuccess?.();
      }
    } catch (error: any) {
      console.error(error);
      setPasswordError(_(msg`Please verify your username and password again`));
    } finally {
      setDispatchingPrimary(false);
    }
  }

  async function submitPasswordResetRequest() {
    setTouched(true);
    if (!email) return;
    setDispatchingSecondary(true);
    try {
      const res = await httpClient.post('/auth/password/reset/request', {
        email,
      });

      if (res?.status === 200) {
        setDispatchedCodeType('reset');
      }
    } catch (error: any) {
      reportServerError({
        title: _(msg`Failed to reset password`),
        error,
      });
    } finally {
      setDispatchingSecondary(false);
    }
  }
}
