import React, { CSSProperties, ReactChild } from 'react';

export default function CenteredInlineBlock(p: {
  outerStyles?: CSSProperties;
  innerStyles?: CSSProperties;
  children?: ReactChild | ReactChild[];
}) {
  return (
    <div style={{ ...p.outerStyles, textAlign: 'center' }}>
      <div style={{ ...p.innerStyles, display: 'inline-block' }}>
        {p.children}
      </div>
    </div>
  );
}
